import React from 'react';

import { StyledChip, IconLeftContainer, IconRightContainer } from './styles';
import { VatixChipProps } from './types';

/**
 * Vatix Chip component. Use/modify this instead of using the MUI Chip component directly.
 * @param {ChipProps} props - The custom props for Vatix Chip component. Extend MUI Chip props.
 * @example
 * <Chip
 *  label="Chip label"
 *  onClick={chipClick}
 *  onClickRightIcon={deleteChip}
 *  variant="outlined"
 *  iconLeft={<AddFilled />}
 *  colors={{
 *   background: '#FFFFFF',
 *   border: 'rgba(0, 0, 0, 0.12)',
 *   iconLeftBackground: "rgba(2, 136, 209, 0.12)"
 *   iconRightBackground: "rgba(2, 136, 209, 0.12)"
 *   hoverBackground: "rgba(0, 0, 0, 0.12)"
 *  }}
 *  borderStyle="dashed"
 *  enableHover
 * />
 */
const Chip: React.FunctionComponent<VatixChipProps> = ({
  variant = 'filled',
  iconLeft,
  iconLeftGapWidth,
  iconLeftDisableInnerMargin,
  iconRight,
  iconRightGapWidth,
  iconRightDisableInnerMargin,
  onClickRightIcon,
  borderStyle = 'solid',
  enableHover = false,
  capitalizeLabel = true,
  ...props
}) => {
  let labelPaddingLeft = iconLeftGapWidth || (iconLeft ? 6 : 10);
  let labelPaddingRight = iconRightGapWidth || (iconRight ? 6 : 10);
  if (typeof props.label !== 'string') {
    labelPaddingLeft = 0;
    labelPaddingRight = 0;
  }
  const sx: { borderColor: string; borderStyle: 'solid' | 'dashed'; '&&:hover'?: Record<string, string> } = {
    borderColor: props.colors?.border || '#D3D6D9',
    borderStyle,
  };
  if (enableHover) {
    sx['&&:hover'] = {
      backgroundColor: props.colors?.hoverBackground ? props.colors?.hoverBackground : 'rgba(0, 0, 0, 0.12)',
      color: props.colors?.hoverColor ? props.colors?.hoverColor : props.colors?.foreground ?? '#000000DE',
      borderColor: props.colors?.hoverBorder || props.colors?.border || '#D3D6D9',
      cursor: 'pointer',
    };
  }

  const newOnDelete = onClickRightIcon || (iconRight && props.onClick);

  const chipRef = React.useRef<HTMLDivElement>(null);

  return (
    <StyledChip
      ref={chipRef}
      variant={variant}
      icon={
        iconLeft ? (
          <IconLeftContainer
            iconBackgroundColor={props.colors?.iconLeftBackground}
            $noLabelMargin={props.label === undefined || props.label === ''}
            $disableMargin={iconLeftDisableInnerMargin}
          >
            {iconLeft}
          </IconLeftContainer>
        ) : (
          undefined
        )
      }
      deleteIcon={
        iconRight ? (
          <IconRightContainer
            iconBackgroundColor={props.colors?.iconRightBackground}
            $noLabelMargin={props.label === undefined || props.label === ''}
            $disableMargin={iconRightDisableInnerMargin}
          >
            {iconRight}
          </IconRightContainer>
        ) : (
          undefined
        )
      }
      onDelete={newOnDelete ? (event) => newOnDelete({ ...event, currentTarget: chipRef.current }) : undefined}
      sx={sx}
      $colors={{
        foreground: props.colors?.foreground,
        background: props.colors?.background,
      }}
      $labelPaddingLeft={labelPaddingLeft}
      $labelPaddingRight={labelPaddingRight}
      $displayPointer={Boolean(props.onClick)}
      $capitalizeLabel={capitalizeLabel}
      {...props}
    />
  );
};

export default Chip;
