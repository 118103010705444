/* eslint-disable max-len */
import * as React from 'react';

function TasksIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M7.3125 17.5C5.96528 17.5 4.82639 17.0347 3.89583 16.1042C2.96528 15.1736 2.5 14.0347 2.5 12.6875V7.3125C2.5 5.96528 2.96528 4.82639 3.89583 3.89583C4.82639 2.96528 5.96528 2.5 7.3125 2.5H12.6875C14.0347 2.5 15.1736 2.96528 16.1042 3.89583C17.0347 4.82639 17.5 5.96528 17.5 7.3125V12.6875C17.5 14.0347 17.0347 15.1736 16.1042 16.1042C15.1736 17.0347 14.0347 17.5 12.6875 17.5H7.3125ZM9.14583 13.2292L14.1458 8.22917L13.25 7.33333L9.14583 11.4375L7.125 9.41667L6.22917 10.3125L9.14583 13.2292ZM7.3125 16.25H12.6875C13.6736 16.25 14.5139 15.9028 15.2083 15.2083C15.9028 14.5139 16.25 13.6736 16.25 12.6875V7.3125C16.25 6.32639 15.9028 5.48611 15.2083 4.79167C14.5139 4.09722 13.6736 3.75 12.6875 3.75H7.3125C6.32639 3.75 5.48611 4.09722 4.79167 4.79167C4.09722 5.48611 3.75 6.32639 3.75 7.3125V12.6875C3.75 13.6736 4.09722 14.5139 4.79167 15.2083C5.48611 15.9028 6.32639 16.25 7.3125 16.25Z"
        fill="#0772E5"
      />
    </svg>
  );
}

export default TasksIcon;
