export enum QuestionFeedTab {
  ACTIVITY = 0,
  TASKS = 1,
}

export interface QuestionFeedPanelProps {
  questionId: string;
  sectionId: string;
  onClose: () => void;
  isSubmitted: boolean;
  defaultTab?: QuestionFeedTab;
}
