import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';
import { UserSearchResponse } from 'vatix-ui/lib/utils/api/types';
import { observer } from 'mobx-react';
import React from 'react';

import { useSearch } from 'vatix-ui/lib/utils/hooks/search';

import { TextField } from '@mui/material';

import { ClearIcon } from '@mui/x-date-pickers';

import API from 'utils/api';

import Chip from 'components/Chip';
import { StyledAutocomplete } from 'components/Input/styles';

import { ProtectorTypeUserProps } from '../types';
import { StyledOption } from '../CustomUserDropdownField/styles';

const loadingOption = [{ uuid: 'loading', name: 'Loading...' }] as UserSearchResponse[];

const CustomMultipleUserDropdownField = ({
  values,
  onChange,
  requiredNotFilled,
}: Omit<ProtectorTypeUserProps, 'value'> & { values: UserSearchResponse[] | undefined }): React.ReactElement => {
  const { results: usersList, loading: loadingUsers, searchQuery: searchQueryUsers } = useSearch(
    API.searchUsers,
    undefined,
    { limit: 20 }
  );

  const [selectedUser, setSelectedUser] = React.useState<UserSearchResponse[] | undefined>(values);

  return (
    <StyledAutocomplete
      multiple
      id="user-dropdown-field"
      options={usersList}
      value={selectedUser}
      isOptionEqualToValue={(option: UserSearchResponse | null, val: UserSearchResponse | null) =>
        val?.uuid !== undefined && val?.uuid === option?.uuid
      }
      renderOption={(props, option: UserSearchResponse) =>
        option.uuid === loadingOption[0].uuid ? (
          <StyledOption>
            <CenteredCircularProgress />
          </StyledOption>
        ) : (
          <StyledOption {...props}>{option.name}</StyledOption>
        )
      }
      getOptionLabel={(option: UserSearchResponse | null) => option?.name as string}
      getOptionDisabled={(option: UserSearchResponse) =>
        option.uuid === loadingOption[0].uuid || selectedUser?.includes(option) || false
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select users"
          error={requiredNotFilled}
          value={selectedUser?.map((user) => user.name).join(', ') || ''}
          onChange={(e) => {
            searchQueryUsers(e.target.value);
          }}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            label={option.name}
            variant="outlined"
            size="small"
            colors={{
              background: '#FFF',
              border: 'rgba(128, 135, 158, 0.14)',
            }}
            iconRight={<ClearIcon />}
            iconRightDisableInnerMargin
            capitalizeLabel={false}
          />
        ))
      }
      loading={loadingUsers}
      noOptionsText="-"
      onChange={(_, rawValue: UserSearchResponse[] | null) => {
        setSelectedUser(rawValue || undefined);
        onChange(rawValue && rawValue.map(({ uuid }) => uuid));
      }}
      fullWidth
      blurOnSelect
    />
  );
};

export default observer(CustomMultipleUserDropdownField);
