import React, { useEffect } from 'react';
import { reverse } from 'named-urls';
import Record from 'vatix-ui/lib/containers/Record/Record';

import MainHeader from 'vatix-ui/lib/components/MainHeader';

import { useRouteMatch } from 'react-router-dom';

import { observer } from 'mobx-react';

import { formatDateTime } from 'vatix-ui/lib/utils/formatters/time';

import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';

import { Button } from 'vatix-ui/lib/components/Button';

import DataError from 'vatix-ui/lib/components/Error/DataError';

import { CircularProgress } from '@mui/material';

import routes from 'core/routes';

import { useNavBarHeight } from 'utils/hooks/navbar';
import { useStore } from 'utils/hooks/store';

import { getRelatedEntityDetails } from 'components/Entities/EntityRelatedPage/helper';

import { ButtonWrapper, Container, HeaderAction, LastPublished, LoadingContainer, SubmissionContainer } from './styles';
import SubmissionSection from './components/SubmissionSection';
import FormPreview from './components/FormPreview';
import RedirectModal from './components/RedirectModal';

const EntitiesFormSubmission: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();

  const match = useRouteMatch<{ draftId: string }>(routes.dashboard.events.draft.details);

  const {
    routing,
    entitiesFormSubmission: {
      data,
      reset,
      isError,
      updating,
      isLoading,
      loadDraft,
      submitting,
      autoSaving,
      onNextClick,
      showPreview,
      onPreviousClick,
      requiredNotFilled,
      showRedirectModal,
      getCurrentSectionProperties,
    },
  } = useStore();

  if (!match) {
    return <DataError />;
  }

  const { entityType } = getRelatedEntityDetails(match.url);

  useEffect(() => {
    if (match.params.draftId) {
      loadDraft(entityType, match.params.draftId);
    }
    return () => {
      reset();
    };
  }, [match.params.draftId]);

  return (
    <Record
      disableMaxContentHeight
      Header={
        <MainHeader
          title="New Submission"
          breadcrumbs={[
            { label: 'Events', link: routes.dashboard.events.toString() },
            { label: 'Overview', link: routes.dashboard.events.toString() },
            { label: 'New Submission', link: reverse(routes.dashboard.events.draft.details) },
          ]}
          action={
            <HeaderAction>
              {autoSaving || submitting ? (
                <LoadingContainer>
                  <CircularProgress size="2rem" sx={{ color: '#9E9E9E' }} />
                  <p>Saving changes...</p>
                </LoadingContainer>
              ) : (
                data?.updatedAt && <LastPublished>{`Last saved: ${formatDateTime(data?.updatedAt)}`}</LastPublished>
              )}
            </HeaderAction>
          }
        />
      }
      Content={() => {
        if (isLoading || updating || !data) {
          return <CenteredCircularProgress key="loading-progress" />;
        }

        if (isError) {
          return <DataError />;
        }

        const currentSectionProperties = getCurrentSectionProperties();

        if (!currentSectionProperties) {
          return <DataError />;
        }

        if (showPreview) {
          return <FormPreview />;
        }

        return (
          <SubmissionContainer>
            <Container>
              {updating ? (
                <CenteredCircularProgress key="loading-progress" />
              ) : (
                <SubmissionSection section={currentSectionProperties} />
              )}
            </Container>
            <ButtonWrapper>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => {
                  if (data.stack.length === 1) {
                    routing.push(routes.dashboard.events.toString());
                  } else {
                    onPreviousClick();
                  }
                }}
              >
                {data.stack.length === 1 ? 'Cancel' : 'Back'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={onNextClick}
                disabled={updating || autoSaving || requiredNotFilled.length > 0}
              >
                Next
              </Button>
            </ButtonWrapper>
            <RedirectModal openModal={showRedirectModal} />
          </SubmissionContainer>
        );
      }}
      navBarHeight={navBarHeight}
    />
  );
};

export default observer(EntitiesFormSubmission);
