import React from 'react';

import { format } from 'date-fns';

import UserRow from 'components/UserRow';

import { EmptyValue } from 'containers/IncidentDetails/components/CustomFieldDisplayValue/styles';

import { StyledContainer, Value, StyledRowTitle } from './styles';
import { NoneEditableValuesProps } from './types';

const InfoField: React.FC<{
  label: string;
  children: React.ReactNode;
}> = ({ label, children }) => (
  <div style={{ flex: 1 }}>
    <div>
      <StyledRowTitle>{label}</StyledRowTitle>
    </div>
    <div>{children}</div>
  </div>
);

const OriginalSubmissionHeader: React.FC<NoneEditableValuesProps> = ({
  id,
  created,
  createdBy,
}): React.ReactElement => {
  const formattedDate = React.useMemo(() => format(new Date(created), 'd MMM yyyy, HH:mm'), [created]);

  return (
    <StyledContainer>
      <InfoField label="Event ID">
        <Value>{`E${id}`}</Value>
      </InfoField>

      <InfoField label="Submitted">
        <Value data-testid="incident-created-date">{formattedDate}</Value>
      </InfoField>

      <InfoField label="Reporter">{createdBy ? <UserRow user={createdBy} /> : <EmptyValue>-</EmptyValue>}</InfoField>
    </StyledContainer>
  );
};

export default OriginalSubmissionHeader;
