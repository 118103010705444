import React from 'react';

import { ActivityType } from 'vatix-ui/lib/utils/api/types';

import { observer } from 'mobx-react';

import { useStore } from 'utils/hooks/store';

import SubmittedModal from 'containers/EntitiesFormSubmission/components/SubmittedModal';

// eslint-disable-next-line max-len
import FileUpload from 'containers/Incidents/components/ReportIncident/components/SubmittedIncidentModal/components/FileUpload';

import { Modal, GreenHeader, InfoText, Title } from './styles';

const EntityFormSubmittingModal: React.FunctionComponent<{ open: boolean; flowWithFile: boolean }> = ({
  open,
  flowWithFile,
}): React.ReactElement => {
  if (flowWithFile) {
    const {
      entitiesFormSubmission: { submissionActivities },
    } = useStore();

    return (
      <Modal
        fullWidth
        open={open}
        maxWidth="md"
        PaperProps={{
          square: true,
        }}
        aria-labelledby="entity-form-submitting-modal"
      >
        <GreenHeader>Event has been successfully submitted!</GreenHeader>

        {submissionActivities?.finishedUploading ? null : (
          <InfoText>Please don’t close this window until all files are uploaded</InfoText>
        )}

        <Title>Submitted files</Title>
        {submissionActivities?.activities
          ?.filter((i) => i.type !== ActivityType.Message)
          .map((i) => (
            <FileUpload activity={i} retryUpload={() => submissionActivities?.retrySubmissionActivity()} />
          ))}
      </Modal>
    );
  }

  return <SubmittedModal openModal={open} />;
};

export default observer(EntityFormSubmittingModal);
