import React from 'react';

import TabPanel from 'components/CustomTabPanel/CustomTabPanel';

import { EntityModules } from 'core/constants';

import { StyledTab, StyledTabs, TabsContainer, Wrapper } from './styles';
import EntityDetailsTab from '../EntityDetailsTab';
import EntityRelatedTab from '../EntityRelatedTab';
import EntitySubmissionTab from '../EntitySubmissionTab';

import { EntityDetailsContentProps } from './types';

const EntityDetailsContent: React.FC<EntityDetailsContentProps> = ({ onError, store }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (_: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

  // we need to keep state in this component, so visibility of fields is not changing while switching tabs
  const [hideEmptyAnswers, setHideEmptyAnswers] = React.useState(false);

  // if it is a entity of entityType 'events' show different tabs
  // in the future we can move these to a separate component, but it should be good for now
  if (store.entityType === EntityModules.Events) {
    return (
      <Wrapper>
        <TabsContainer>
          <StyledTabs value={value} onChange={handleChange}>
            <StyledTab label="Details" id="overview-tab" />
            <StyledTab label="Original Submission" id="original-submission-tab" />
            <StyledTab label="Related" id="related-tab" />
          </StyledTabs>
          <TabPanel value={value} index={0}>
            <EntityDetailsTab store={store} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EntitySubmissionTab
              store={store}
              hideEmptyAnswers={hideEmptyAnswers}
              setHideEmptyAnswers={setHideEmptyAnswers}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <EntityRelatedTab onError={onError} store={store} />
          </TabPanel>
        </TabsContainer>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <TabsContainer>
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab label="Details" id="overview-tab" />
          <StyledTab label="Related" id="related-tab" />
        </StyledTabs>
        <TabPanel value={value} index={0}>
          <EntityDetailsTab store={store} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EntityRelatedTab onError={onError} store={store} />
        </TabPanel>
      </TabsContainer>
    </Wrapper>
  );
};

export default EntityDetailsContent;
