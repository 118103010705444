import React, { forwardRef } from 'react';

import { observable } from 'mobx';

import { ClickAwayListener, IconButton, MenuItem } from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseIcon from '@mui/icons-material/Close';

import { ExpandMore, ExpandLess } from '@mui/icons-material';

import { StyledTextField } from 'components/Input/styles';

import { useStore } from 'utils/hooks/store';

import { Header, StyledPopover } from './styles';

import { ContainerHeaderProps } from './types';

const ContainerHeader = forwardRef<HTMLDivElement, ContainerHeaderProps>(
  ({ expanded, setExpanded, label, onRemove, id }, ref) => {
    const { entityLayout } = useStore();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [editSectionTitle, setEditSectionTitle] = React.useState(false);
    const [sectionTitle, setSectionTitle] = React.useState(label || '');

    const handleClickOptions = (event: React.MouseEvent<HTMLButtonElement>): void => {
      if (anchorEl) {
        setAnchorEl(null);
      } else {
        setAnchorEl(event.currentTarget);
      }
    };

    const handleExpandClick = (): void => {
      setExpanded((prevState) => !prevState);
    };

    const handleRenameSection = (): void => {
      entityLayout.renameSection(id, sectionTitle);
      setEditSectionTitle(false);
    };

    const handleDiscardChanges = (): void => {
      setSectionTitle(label || '');
      setEditSectionTitle(false);
    };

    return (
      <Header $expanded={expanded} ref={ref}>
        <div style={{ display: 'flex', alignItems: 'center', flex: 3 }}>
          {expanded ? (
            <ExpandLess onClick={handleExpandClick} aria-label="show less" color="disabled" />
          ) : (
            <ExpandMore onClick={handleExpandClick} aria-label="show more" color="disabled" />
          )}
          {editSectionTitle ? (
            <ClickAwayListener onClickAway={handleDiscardChanges}>
              <StyledTextField
                fullWidth
                size="small"
                value={sectionTitle}
                style={{ backgroundColor: '#fff', margin: '0 8px' }}
                onChange={(event) => setSectionTitle(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleRenameSection();
                  }
                }}
              />
            </ClickAwayListener>
          ) : (
            <div onClick={() => setEditSectionTitle(true)} tabIndex={0} role="button" data-testid="section-title">
              <p style={{ marginLeft: '8px' }} id={`section-title-${sectionTitle}`}>
                {sectionTitle}
              </p>
            </div>
          )}
        </div>
        {editSectionTitle ? (
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <IconButton onClick={handleDiscardChanges}>
              <CloseIcon style={{ width: '16px', height: '16px' }} htmlColor="rgba(0, 0, 0, 0.54)" />
            </IconButton>
            <IconButton onClick={handleRenameSection}>
              <CheckOutlinedIcon style={{ width: '16px', height: '16px' }} htmlColor="rgba(0, 0, 0, 0.54)" />
            </IconButton>
          </div>
        ) : (
          <IconButton onClick={handleClickOptions} style={{ padding: 0 }}>
            <MoreVertIcon htmlColor="rgba(0, 0, 0, 0.54)" />
          </IconButton>
        )}

        {onRemove && (
          <StyledPopover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            id="popover-remove-rename-section"
            data-testid="popover-remove-rename-section"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <MenuItem id="remove-section-button" onClick={onRemove}>
              Delete
            </MenuItem>
          </StyledPopover>
        )}
      </Header>
    );
  }
);

export default observable(ContainerHeader);
