import { css, styled } from 'styled-components';

export const SortableItemContainer = styled.div`
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border-radius: 8px;
  border: 1px solid #e2e4e6;
  margin: 4px 0;
`;

export const ItemContainer = styled.div`
  border-radius: 8px;
  background: #fff;
  border-left: 1px solid #e2e4e6;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  width: 100%;
  height: 58px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  gap: 8px;

  flex: 1;
`;

export const OptionTitle = styled.p<{ $maxWidth?: number }>`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  margin: 0;
  padding: 0;

  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth}px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export const SubContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  margin: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  min-height: 40px;
  max-height: 40px;
`;
