import styled from 'styled-components';

import vatix from 'theme/vatix';

export const QuestionHeader = styled.div<{ $sectionZero: boolean }>`
  width: 100%;
  background: ${(props) => (props.$sectionZero ? '#fff' : '#f5f5f5')};
  padding: 12px 20px;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
`;

export const QuestionTitle = styled.div`
  color: #101d31;
  font-size: 18px;
  font: normal normal normal 18px/24px Roboto;
  margin: 0;
  align-items: center;
  display: flex;
`;

export const MandatoryQuestion = styled.p`
  color: ${vatix.ui.snackbar.error};
  margin: 0;
  margin-left: 6px;
`;

export const AnswerRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
`;
