import styled from 'styled-components';

import Dialog from '@mui/material/Dialog';

const StyledModal = styled(Dialog)`
  .MuiPaper-root {
    padding: 24px;
    border-radius: 8px;
    border: 0px solid #eee;
    background: #fff;
    box-shadow: 0px 8px 50px 0px rgba(7, 5, 26, 0.07);
  }
`;

export const Modal = styled(StyledModal)`
  .MuiPaper-root {
    max-width: 660px;
    border-radius: 8px;
  }
`;

export const GreenHeader = styled.h2`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.15px;
  text-transform: capitalize;
  color: #4caf50;
  margin: 0;
`;

export const InfoText = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  margin: 0;
`;

export const Title = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  margin-top: 24px;
`;
