import { action, makeObservable, observable } from 'mobx';
import { eventBus } from 'mobx-event-bus2';
import {
  ActivityResponse,
  ActivityType,
  ActivityType as VatixActivityType,
  FileActivityResponse,
  FileUploadProgressResponse,
  MessageActivityResponse,
  UserNameResponse,
  UserSearchResponse,
} from 'vatix-ui/lib/utils/api/types';
import DetailsActivities from 'vatix-ui/lib/utils/stores/DetailsActivities';

import Axios from 'axios';

import RootStore from 'stores/Root';
import API from 'utils/api';
import Logger from 'utils/logger';
import { MAX_FILE_SIZE } from 'core/constants';

export default class SubmissionActivities extends DetailsActivities<RootStore, typeof API> {
  @observable finishedUploading = false;

  @observable activitySucceed: string[] = [];

  @observable activityFailed: string[] = [];

  @observable entityId = '';

  @observable retrying = false;

  @observable entityType = 'events';

  @observable updatingActivities = false;

  constructor(rootStore: RootStore, api: typeof API, entityType: string) {
    super(rootStore, api);

    makeObservable(this);
    this.activities = [];
    this.entityType = entityType;
    eventBus.register(this);
  }

  @action.bound
  updateActivity(updatedActivity: ActivityResponse): void {
    const index = this.activities?.findIndex((activity) => activity.uuid === updatedActivity.uuid);
    if (index !== undefined && index !== -1 && this.activities !== undefined) {
      this.activities[index] = updatedActivity;
    }
  }

  @action.bound
  changeFinishedUploading(value: boolean): void {
    this.finishedUploading = value;
  }

  @action.bound
  addSubmissionMessage(message: string, taggedUsers?: UserSearchResponse[]): void {
    if (this.error || !this.activities) {
      return;
    }
    const created = new Date().toJSON();
    const data = {
      message,
      taggedUsers,
      creator: { name: 'Reporter', uuid: 'reporter-uuid' },
      uuid: `upload-message-${message}-${created}`,
      created,
      type: ActivityType.Message as VatixActivityType,
    };

    this.activities = [...this.activities, data];
  }

  @action.bound
  addSubmissionFile(file: File): void {
    if (this.error || !this.activities) {
      return;
    }
    if (file.size > MAX_FILE_SIZE) {
      this.rootStore.notification.enqueueErrorSnackbar('File size exceeds the limit of 52 MB. Upload unsuccessful.');
      return;
    }

    const created = new Date().toJSON();
    const data = {
      created,
      creator: { name: 'Reporter', uuid: 'reporter-uuid' },
      file,
      filename: file.name,
      size: file.size,
      thumbnail: URL.createObjectURL(file),
      uuid: `upload-task-${file.name}-${created}`,
      type: ActivityType.File as VatixActivityType,
    };

    this.activities = [...this.activities, data];
  }

  @action.bound
  async addSubmissionActivity(entityId: string): Promise<void> {
    this.rootStore.entitiesFormSubmission.changeShowConfirmPopup(true);

    this.entityId = entityId;

    if (this.error || !this.activities) {
      return;
    }

    this.updatingActivities = true;

    for await (const activity of this.activities.reverse()) {
      if (activity.type === ActivityType.File) {
        const { file, filename, created, uuid } = activity as FileActivityResponse;
        const { size } = (file as unknown) as File;
        const cancelTokenSource = Axios.CancelToken.source();
        const uploadActivity = this.createUploadActivity((file as unknown) as File, filename, created);
        try {
          this.updateActivity(uploadActivity);

          await this.api.uploadEntitiesPublicReporterActivitiesFile(
            this.entityType,
            entityId,
            (file as unknown) as File,
            (event: ProgressEvent) => this.onUploadProgress(event, uploadActivity, size),
            cancelTokenSource
          )();
          this.activitySucceed.push(uuid);
          this.updateActivity({
            ...uploadActivity,
            loaded: size,
          } as FileUploadProgressResponse);
        } catch (e) {
          this.activityFailed.push(
            ...this.activities.filter((j) => !this.activitySucceed.includes(j.uuid)).map((k) => k.uuid)
          );
          this.updateActivity(activity);
          Logger.error(`Invalid add file API result: Entity ID: ${entityId}`, e);
          this.rootStore.notification.enqueueErrorSnackbar('Attachment cannot be created right now.');
          return;
        }
      } else if (activity.type === ActivityType.Message) {
        const { message, taggedUsers, uuid } = activity as MessageActivityResponse;
        try {
          await this.api.addEntitiesPublicReporterActivitiesMessage(this.entityType, entityId, message, taggedUsers)();
          this.activitySucceed.push(uuid);
        } catch (e) {
          this.activityFailed.push(
            ...this.activities.filter((j) => !this.activitySucceed.includes(j.uuid)).map((k) => k.uuid)
          );
          Logger.error(`Invalid add message API result: Entity ID: ${entityId}`, e);
          this.rootStore.notification.enqueueErrorSnackbar('Message cannot be add right now');
          return;
        }
      }
    }
    this.changeFinishedUploading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.updatingActivities = false;
    this.rootStore.entitiesFormSubmission.changeShowConfirmPopup(false);
  }

  @action.bound
  async retrySubmissionActivity(): Promise<void> {
    this.retrying = true;
    const activities = (this.activities?.filter(
      (i) => !this.activitySucceed.includes(i.uuid)
    ) as unknown) as ActivityResponse[];
    for await (const activity of activities.reverse()) {
      if (activity.type === ActivityType.Message) {
        const { message, taggedUsers, uuid } = activity as MessageActivityResponse;
        this.activityFailed = this.activityFailed.filter((i) => i !== uuid);
        try {
          await this.api.addEntitiesPublicReporterActivitiesMessage(
            this.entityType,
            this.entityId,
            message,
            taggedUsers
          )();
          this.activitySucceed.push(uuid);
        } catch (e) {
          this.activityFailed.push(uuid);
          this.retrying = false;
          Logger.error(`Invalid add message API result: Entity ID: ${this.entityId}`, e);
          this.rootStore.notification.enqueueErrorSnackbar('Message cannot be add right now');
          return;
        }
      }
    }
    this.retrying = false;
    if (this.activityFailed.length === 0 && this.activitySucceed.length === this.activities?.length) {
      this.changeFinishedUploading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      this.rootStore.entitiesFormSubmission.changeShowConfirmPopup(false);
    }
  }

  @action.bound
  async retrySubmissionFile(fileUuid: string): Promise<void> {
    const retryActivity = this.activities?.find((i) => i.uuid === fileUuid);
    if (retryActivity) {
      this.activityFailed = this.activityFailed.filter((i) => i !== fileUuid);

      const { file, filename, created, uuid } = retryActivity as FileActivityResponse;
      const uploadActivity = this.createUploadActivity((file as unknown) as File, filename, created);
      const { size } = (file as unknown) as File;
      const cancelTokenSource = Axios.CancelToken.source();
      try {
        this.updateActivity(uploadActivity);

        await this.api.uploadEntitiesPublicReporterActivitiesFile(
          this.entityType,
          this.entityId,
          (file as unknown) as File,
          (event: ProgressEvent) => this.onUploadProgress(event, uploadActivity, size),
          cancelTokenSource
        )();
        this.activitySucceed.push(uuid);
        this.updateActivity({
          ...uploadActivity,
          loaded: size,
        } as FileUploadProgressResponse);

        if (this.activityFailed.length === 0 && this.activitySucceed.length === this.activities?.length) {
          this.changeFinishedUploading(true);
          await new Promise((resolve) => setTimeout(resolve, 2000));
          this.rootStore.entitiesFormSubmission.changeShowConfirmPopup(false);
        }
      } catch (e) {
        this.activityFailed.push(uuid);
        Logger.error(`Invalid add file API result: Entity ID: ${this.entityId}`, e);
        this.rootStore.notification.enqueueErrorSnackbar('Attachment cannot be created right now.');
      }
    }
  }

  // Utility function to create upload activity
  createUploadActivity = (file: File, filename: string, created: string): FileUploadProgressResponse => {
    const { name, size } = file;
    const cancelTokenSource = Axios.CancelToken.source();
    return {
      uuid: `upload-task-${filename}-${created}`,
      type: ActivityType.FileUploadProgress,
      creator: this.rootStore.session?.user as UserNameResponse,
      created: new Date().toISOString(),
      name,
      size,
      loaded: 0,
      onCancel: () => cancelTokenSource.cancel('User cancelled upload'),
      thumbnail: URL.createObjectURL(file),
    };
  };

  // Utility function for handling upload progress
  onUploadProgress = (event: ProgressEvent, uploadActivity: FileUploadProgressResponse, size: number): void => {
    const uploaded = event.loaded - uploadActivity.loaded;
    this.updateActivity({
      ...uploadActivity,
      loaded: event.loaded - size / 10 > 0 ? event.loaded - size / 10 : 0,
      speed: (uploaded / event.timeStamp) * 1000,
    } as FileUploadProgressResponse);
  };
}
