import styled from 'styled-components';

import { LinearProgress } from '@mui/material';

export const StyledLinearProgress = styled(LinearProgress)<{ $completed: boolean }>`
  border-radius: 4px;
  .MuiLinearProgress-barColorPrimary {
    background-color: ${({ $completed }) => ($completed ? 'rgba(76, 175, 80, 1)' : '')};
  }
`;

export const FileSize = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 10px;
`;

export const FileName = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
`;

export const FileUploadContainer = styled.div`
  display: flex;
  padding: 9px 16px;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 4px 0;
`;
