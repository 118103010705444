import React from 'react';

import { observer } from 'mobx-react';

import { reverse } from 'named-urls';

import { useHistory } from 'react-router-dom';

import { IconButton } from '@mui/material';

import { Close } from '@mui/icons-material';

import { useStore } from 'utils/hooks/store';

import AnswerDetails from 'stores/FormDetails/AnswerDetails';

import AnswerTasks from 'stores/FormDetails/AnswerTasks';

import routes from 'core/routes';

import TabPanel from 'components/CustomTabPanel/CustomTabPanel';

import { HeaderContainer, DefaultContainer, StyledTabs, StyledTab, QuestionHeader } from './styles';

import TaskList from '../TaskList';
import { QuestionFeedPanelProps, QuestionFeedTab } from './types';
import { DataContainer } from '../TaskList/styles';

import TaskActivity from './TaskActivity';

const QuestionDrawer: React.FunctionComponent<QuestionFeedPanelProps> = ({
  questionId,
  sectionId,
  onClose,
  defaultTab = QuestionFeedTab.ACTIVITY,
}): React.ReactElement => {
  const { formDetails } = useStore();

  const [value, setValue] = React.useState(defaultTab);

  const handleChange = (_: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

  const history = useHistory();
  const onTaskClick = (taskId: string): void => {
    history.push(reverse(routes.dashboard.tasks.details, { taskId }));
  };

  const sectionID = formDetails.details?.template.content.order.indexOf(sectionId) as number;
  const answer = formDetails.details?.answers[sectionID][questionId] as AnswerDetails;

  const position = formDetails.details?.questionIds.indexOf(questionId) as number;

  return (
    <>
      <HeaderContainer>
        <QuestionHeader>{`Question ${position + 1}`}</QuestionHeader>
        <IconButton
          onClick={onClose}
          id="close-details-link"
          sx={{
            borderRadius: '50%',
            border: '1px solid  #E0E0E0',
            width: '32px',
            height: '32px',
          }}
        >
          <Close
            sx={{
              width: '18px',
              height: '18px',
            }}
          />
        </IconButton>
      </HeaderContainer>
      <StyledTabs value={value} onChange={handleChange}>
        <StyledTab label="Activity feed" id="question-activity-feed" />
        <StyledTab label="Tasks" id="question-tasks" />
      </StyledTabs>

      <DefaultContainer>
        <TabPanel value={value} index={0} customStyle={{ height: '100%' }}>
          <DataContainer>
            <TaskActivity questionId={questionId} sectionId={sectionId} />
          </DataContainer>
        </TabPanel>
        <TabPanel value={value} index={1} customStyle={{ height: '100%' }}>
          <TaskList onSelected={(task) => onTaskClick(task.uuid)} tasks={answer?.tasks as AnswerTasks} />
        </TabPanel>
      </DefaultContainer>
    </>
  );
};

export default observer(QuestionDrawer);
