import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'vatix-ui/lib/components/Button';

import { reverse } from 'named-urls';

import { useSearch } from 'vatix-ui/lib/utils/hooks/search';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import CustomModal from 'components/CustomModal';
import { useStore } from 'utils/hooks/store';
import routes from 'core/routes';
import { EntityModules } from 'core/constants';
import API from 'utils/api/api';
import Logger from 'utils/logger';

import { EntityFormType } from 'utils/api/types';

import { StyledAutocomplete, StyledTextField } from 'components/Input/styles';

import { SelectTitle, Text, TitleText, StyledOption } from './styles';
import { CreateEventModalProps, ModalState } from './types';

const getModalState = (withoutDrafts: boolean, drafts: EntityFormType[], forms: EntityFormType[]): ModalState => {
  if (!Array.isArray(forms) || !Array.isArray(drafts)) {
    return ModalState.NO_FORMS;
  }

  if (!withoutDrafts && drafts.length > 0) {
    return ModalState.DRAFT_EXISTS;
  }

  if (forms.length > 1) return ModalState.MULTIPLE_FORMS;
  if (forms.length === 1) return ModalState.SINGLE_FORM;
  return ModalState.NO_FORMS;
};

// 1. user has a draft - ask if they want to continue or start a new event
// 2. if they want to continue, open the draft details
// 3. if they want to start a new event, open the create event modal with select form
// 4. user has no draft and one form - create a new draft and open the draft details
// 5. user has no draft and more than one form - open the create event modal with select form

const CreateEventModal: React.FunctionComponent<CreateEventModalProps> = ({
  onClose,
  openModal,
  forms,
  drafts,
}): React.ReactElement => {
  const { notification, routing } = useStore();

  const request = React.useCallback(
    (params?: Partial<{ query: string; limit: number }>) =>
      API.searchForms('events', { ...params, audience: '-public' }),
    []
  );

  const { results, loading, searchQuery } = useSearch(request);

  const [selectedForm, setSelectedForm] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [modalState, setModalState] = React.useState<ModalState>(ModalState.NO_FORMS);

  React.useEffect(() => {
    if (!openModal) return;
    setModalState(getModalState(false, drafts, forms));
  }, [forms, drafts, openModal]);

  const onCloseModal = (): void => {
    onClose(false);
    setSelectedForm(null);
  };

  const handleStartNewEventWithSelectedForm = async (): Promise<void> => {
    if (!selectedForm) {
      notification.enqueueErrorSnackbar('Please select a form template');
      return;
    }

    try {
      setIsLoading(true);
      const { data } = await API.createEntityFormsDrafts(EntityModules.Events, selectedForm)();
      routing.push(reverse(routes.dashboard.events.draft.details, { draftId: data.uuid }));
    } catch (err) {
      notification.enqueueErrorSnackbar('Failed to create new event. Please try again.');
      Logger.error(err as string);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartNewEvent = React.useCallback(async (): Promise<void> => {
    if (forms.length === 0) {
      notification.enqueueErrorSnackbar('No forms found');
      return;
    }
    if (forms.length === 1) {
      try {
        setIsLoading(true);
        const { data } = await API.createEntityFormsDrafts(EntityModules.Events, forms[0].uuid)();
        routing.push(reverse(routes.dashboard.events.draft.details, { draftId: data.uuid }));
      } catch (err) {
        notification.enqueueErrorSnackbar('Failed to create new event. Please try again.');
        Logger.error(err as string);
      } finally {
        setIsLoading(false);
      }
    }
    setModalState(getModalState(true, drafts, forms));
  }, [forms, drafts]);

  const handleContinueDraft = (): void => {
    routing.push(reverse(routes.dashboard.events.draft.details, { draftId: drafts[0].uuid }));
    onClose(true);
  };

  const getContent = (): React.ReactElement => {
    switch (modalState) {
      case ModalState.DRAFT_EXISTS:
        return (
          <TitleText>
            You have an unsaved event submission. Would you like to continue your draft or start a new event?
          </TitleText>
        );
      case ModalState.MULTIPLE_FORMS:
        return (
          <>
            <Text>Choose a form to begin your event submission.</Text>
            <SelectTitle>Form template</SelectTitle>
            <StyledAutocomplete
              options={results}
              loading={loading}
              id="form-dropdown-field"
              data-testid="form-dropdown-field"
              onChange={(_, value) => {
                setSelectedForm(value?.uuid || null);
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option: UuidableName) => <StyledOption {...props}>{option.name}</StyledOption>}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  placeholder="Select form template"
                  onChange={(e) => {
                    searchQuery(e.target.value);
                  }}
                />
              )}
              style={{
                height: 'fit-content',
                width: '100%',
              }}
              size="medium"
              sx={{
                '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
                  fontSize: '16px',
                },
                '& + .MuiChip-root': {
                  fontSize: '16px',
                },
              }}
            />
          </>
        );
      case ModalState.NO_FORMS:
        return <div>No forms found</div>;
      default:
        return <div>No forms found</div>;
    }
  };

  const getCancelButton = (): React.ReactElement =>
    modalState === ModalState.MULTIPLE_FORMS ? (
      <Button
        size="large"
        variant="outlined"
        id="cancel-button"
        key="cancel-button"
        disabled={isLoading}
        onClick={onCloseModal}
        data-testid="cancelButton"
      >
        Cancel
      </Button>
    ) : (
      <Button
        size="large"
        variant="outlined"
        disabled={isLoading}
        id="start-new-event-button"
        key="start-new-event-button"
        onClick={handleStartNewEvent}
        data-testid="startNewEventButton"
      >
        Start New Event
      </Button>
    );

  const getSubmitButton = (): React.ReactElement =>
    modalState === ModalState.MULTIPLE_FORMS ? (
      <Button
        size="large"
        variant="contained"
        disabled={isLoading}
        id="create-event-button"
        key="create-event-button"
        data-testid="createEventButton"
        onClick={handleStartNewEventWithSelectedForm}
      >
        Start Report
      </Button>
    ) : (
      <Button
        size="large"
        variant="contained"
        disabled={isLoading}
        id="continue-draft-button"
        key="continue-draft-button"
        onClick={handleContinueDraft}
        data-testid="continueDraftButton"
      >
        Continue Draft
      </Button>
    );

  return (
    <CustomModal
      openModal={openModal}
      onClose={onCloseModal}
      modalTitle={modalState === ModalState.DRAFT_EXISTS ? 'Unsaved changes' : 'Report New Event'}
      buttonsSpacing={modalState === ModalState.MULTIPLE_FORMS ? 'flex-end' : 'space-between'}
      cancelButton={getCancelButton()}
      submitButton={getSubmitButton()}
    >
      {getContent()}
    </CustomModal>
  );
};

export default observer(CreateEventModal);
