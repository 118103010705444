import { AnswerTypes, FormBuilderType, EnumOptions, ProtectorType } from 'utils/api/types';

interface GetContentForKeyProps {
  form: FormBuilderType;
  content: Record<string, AnswerTypes>;
  key: string;
  sectionKey?: string;
}

export const getContentForKey = ({
  form,
  content,
  key,
  sectionKey,
}: GetContentForKeyProps): AnswerTypes | undefined => {
  if (sectionKey) {
    const section = form.properties[sectionKey];
    const { questionType } = section.properties[key];
    if (questionType === ProtectorType.MultiChoice || questionType === ProtectorType.SingleChoice) {
      const enumOptions = (section.properties[key].properties?.enum as unknown) as EnumOptions;
      const keyToValueMap = enumOptions.reduce((acc, item) => {
        acc[item.key] = item.value;
        return acc;
      }, {} as Record<string, string>);

      const contentValue = content[key];
      if (!contentValue) {
        return undefined;
      }

      return (contentValue as string[]).map((k) => keyToValueMap[k]);
    }
  }

  return content[key];
};

export const checkIfAllAnswersInSectionAreEmpty = (
  form: FormBuilderType,
  sectionKey: string,
  content: Record<string, AnswerTypes>
): boolean => {
  const section = form.properties[sectionKey];
  return Object.keys(section.properties).every((property) => content[property] === undefined);
};
