/* eslint-disable max-len */
import DataMixin from 'vatix-ui/lib/utils/stores/DataMixin';

import { action, makeObservable, observable, reaction } from 'mobx';

import { isEqual } from 'lodash';

import API from 'utils/api';
import RootStore from 'stores/Root';

import { DEFAULT_SETTINGS, FormAudience, FormSettings, FormSettingsResponse } from './types';

export default class FormBuilderSettings extends DataMixin<typeof API, RootStore> {
  @observable settings: FormSettings;

  @observable settingsWasEdited: boolean = false;

  @observable initialSettings: FormSettings;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api);

    makeObservable(this);

    this.settings = DEFAULT_SETTINGS;

    this.initialSettings = this.settings;

    reaction(
      () => this.settings,
      () => {
        this.isModified();
      }
    );
  }

  @action.bound
  isModified(): void {
    if (this.settings === undefined) return;
    this.settingsWasEdited = !isEqual(this.settings, this.initialSettings);
  }

  @action.bound
  loadedSettings(settings: FormSettingsResponse): void {
    const transformedSettings: FormSettings = {
      isActive: settings.isActive,
      audience: settings.audience.length > 1 ? FormAudience.ALL : settings.audience[0],
      notifications: {
        teamManagers: settings.notifications.teamManagers,
        specificUsersActive: settings.notifications.specificUsers.length > 0,
        specificUsers: settings.notifications.specificUsers,
      },
      confirmationEmail: {
        ...settings.confirmationEmail,
      },
      publicSubmission: {
        publicSubmissionType:
          settings.publicSubmission.name || settings.publicSubmission.email || settings.publicSubmission.phone
            ? 'Named'
            : 'Anonymous',
        options: {
          ...settings.publicSubmission,
        },
      },
      publicUuid: settings.publicUuid,
    };
    this.initialSettings = transformedSettings;
    this.settings = transformedSettings;
  }

  @action.bound
  setSettings(settings: FormSettings): void {
    this.settings = { ...this.settings, ...settings };
  }

  validateSettings(): boolean {
    if (this.settings.notifications.specificUsersActive && this.settings.notifications.specificUsers.length === 0) {
      this.store.notification.enqueueErrorSnackbar('Please add at least one specific user to notify');
      return false;
    }
    return true;
  }

  formSavedSettings(): FormSettingsResponse {
    const audience =
      this.settings.audience === FormAudience.ALL ? [FormAudience.USER, FormAudience.PUBLIC] : [this.settings.audience];
    const settings: FormSettingsResponse = {
      isActive: this.settings.isActive,
      audience,
      notifications: {
        teamManagers: this.settings.notifications.teamManagers,
        specificUsers: this.settings.notifications.specificUsers.map((user) => ({ uuid: user.uuid })),
      },
      confirmationEmail: this.settings.confirmationEmail.isActive
        ? { ...this.settings.confirmationEmail }
        : {
            isActive: false,
          },
      publicSubmission: {
        name:
          this.settings.publicSubmission.publicSubmissionType === 'Named'
            ? this.settings.publicSubmission.options.name
            : false,
        email:
          this.settings.publicSubmission.publicSubmissionType === 'Named'
            ? this.settings.publicSubmission.options.email
            : false,
        phone:
          this.settings.publicSubmission.publicSubmissionType === 'Named'
            ? this.settings.publicSubmission.options.phone
            : false,
      },
      publicUuid: this.settings.publicUuid,
    };

    return settings;
  }

  private getAudienceSpecificMessages(): Record<
    FormAudience,
    {
      confirmationEmail: string;
      permissions: string;
    }
  > {
    return {
      [FormAudience.USER]: {
        confirmationEmail:
          "Enable or disable confirmation emails for the form. If this notification is enabled, we send an email to the reporter's email address upon submission.",
        permissions:
          'Control who gets notified and granted access when this form is submitted. Use team-based rules, individual users, or a combination of both.',
      },
      [FormAudience.PUBLIC]: {
        confirmationEmail:
          "Enable or disable confirmation emails for the form. If this notification is enabled, we send an email to the reporter's email address provided in the form (if any).",
        permissions: 'Control who gets notified and granted access to the event records when this form is submitted.',
      },
      [FormAudience.ALL]: {
        confirmationEmail:
          'Enable or disable confirmation emails for the form. If this notification is enabled, we send an email to logged-in users and public reporters, using the email address provided in the form (if any).',
        permissions:
          'Control who gets notified and granted access when this form is submitted. Use team-based rules (not applicable to public submissions), individual users, or a combination of both.',
      },
    };
  }

  getConfirmationEmailSubheading(): string {
    return this.getAudienceSpecificMessages()[this.settings.audience].confirmationEmail;
  }

  getPermissionsNotificationsSubheading(): string {
    return this.getAudienceSpecificMessages()[this.settings.audience].permissions;
  }

  resetSettings(): void {
    this.settings = DEFAULT_SETTINGS;
    this.initialSettings = DEFAULT_SETTINGS;
    this.settingsWasEdited = false;
  }
}
