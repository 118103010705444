import { action, observable, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import { EntityModules, MonitoringMethod, UserRole } from 'core/constants';
import {
  DashboardList,
  ProfileOrganisationResponse,
  SurveyResponse,
  UserLicensesResponse,
  UserProfileResponse,
} from 'utils/api/types';
import { EventType } from 'utils/events/constants';
import { ActionEvent, UpdatedProfilePayload, UpdatedUserPayload } from 'utils/events/types';

import { ADMIN_AND_MANAGERS } from '../../containers/Dashboard/components/Routing/types';

class SessionUser {
  uuid: string;

  @observable role: UserRole;

  email: string;

  @observable name: string;

  @observable phoneNumber: string | null;

  monitoringMethod: MonitoringMethod;

  organisation: ProfileOrganisationResponse;

  licenses: UserLicensesResponse;

  activeEntityModules: EntityModules[];

  activeOrganisationEntityModules: EntityModules[];

  @observable twoFactorAuthEnabled: boolean;

  @observable twoFactorAuthAllowed: boolean;

  @observable ssoUser: boolean;

  @observable showSurvey: boolean;

  @observable survey: SurveyResponse;

  @observable dashboards: DashboardList | null;

  @observable appCues: boolean;

  @observable helpScout: boolean;

  isProtectorActive: boolean;

  isWorkflowsActive: boolean;

  readOnly: boolean;

  @observable showObjectManager: boolean;

  constructor(data: UserProfileResponse) {
    makeObservable(this);
    this.uuid = data.uuid;
    this.name = data.name;
    this.role = data.role;
    this.phoneNumber = data.phoneNumber;
    this.email = data.email;
    this.monitoringMethod = data.monitoringMethod;
    this.organisation = data.organisation;
    this.twoFactorAuthEnabled = data.twoFactorAuthEnabled;
    this.twoFactorAuthAllowed = data.twoFactorAuthAllowed;
    this.ssoUser = data.ssoUser;
    this.isProtectorActive = data.isProtectorActive;
    this.isWorkflowsActive = data.isWorkflowsActive;
    this.licenses = data.licenses;
    this.showSurvey = data.showSurvey;
    this.survey = data.survey;
    this.dashboards = data.dashboards;
    this.appCues = data.appCues;
    this.helpScout = data.helpScout;
    this.readOnly = data.readOnly;
    this.showObjectManager = data.showObjectManager;

    this.activeEntityModules = this.getAllAvailableEntitiesForUser();
    this.activeOrganisationEntityModules = this.getAllAvailableEntitiesByOrganisation();

    eventBus.register(this);
  }

  @action
  enableTwoFactorAuth(): void {
    this.twoFactorAuthEnabled = true;
  }

  @action
  disableTwoFactorAuth(): void {
    this.twoFactorAuthEnabled = false;
  }

  @subscribe(EventType.UpdatedUser)
  @subscribe(EventType.UpdatedProfile)
  @action
  updatedUser({ payload }: ActionEvent<UpdatedProfilePayload | UpdatedUserPayload>): void {
    if (payload.uuid !== this.uuid) {
      return;
    }

    this.name = payload.name;
    this.phoneNumber = payload.phoneNumber;
    this.role = payload.role;
  }

  getAllAvailableEntitiesForUser(): EntityModules[] {
    const availableEntities: EntityModules[] = [];
    // sites
    if (ADMIN_AND_MANAGERS.includes(this.role) && this.organisation.maximumSitesAllowed > 0) {
      availableEntities.push(EntityModules.Sites);
    }
    // assets
    if (ADMIN_AND_MANAGERS.includes(this.role) && this.organisation.maximumAssetsAllowed > 0) {
      availableEntities.push(EntityModules.Assets);
    }
    // suppliers
    if (ADMIN_AND_MANAGERS.includes(this.role) && this.organisation.maximumSuppliersAllowed > 0) {
      availableEntities.push(EntityModules.Suppliers);
    }
    // risks
    if (this.role === UserRole.Admin && this.licenses.risks) {
      availableEntities.push(EntityModules.Risks);
    }
    // events
    if (this.licenses.events) {
      availableEntities.push(EntityModules.Events);
    }
    return availableEntities;
  }

  getAllAvailableEntitiesByOrganisation(): EntityModules[] {
    const availableEntities: EntityModules[] = [];
    // sites
    if (this.organisation.maximumSitesAllowed > 0) {
      availableEntities.push(EntityModules.Sites);
    }
    // assets
    if (this.organisation.maximumAssetsAllowed > 0) {
      availableEntities.push(EntityModules.Assets);
    }
    // suppliers
    if (this.organisation.maximumSuppliersAllowed > 0) {
      availableEntities.push(EntityModules.Suppliers);
    }
    // risks
    if (this.licenses.risks) {
      availableEntities.push(EntityModules.Risks);
    }
    // events
    if (this.licenses.events) {
      availableEntities.push(EntityModules.Events);
    }
    return availableEntities;
  }

  hasOnlyLoneWorkingLicenseEnabled(): boolean {
    return this.licenses.protectorAlarms && !this.licenses.protectorIncidents && !this.licenses.workflows;
  }
}

export default SessionUser;
