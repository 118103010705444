import React from 'react';

import MainHeader from 'vatix-ui/lib/components/MainHeader';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { Button } from 'vatix-ui/lib/components/Button';

import { reverse } from 'named-urls';

import routes from 'core/routes';
import { useNavBarHeight } from 'utils/hooks/navbar';

import API from 'utils/api/api';

import Logger from 'utils/logger';

import { EntityModules } from 'core/constants';

import { EntityFormType } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import CreateEventModal from './components/CreateEventModal';

import EventsTable from './components/EventsTable';

const Events: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();
  const refreshRef = React.useRef<Function | null>(null);
  const { routing } = useStore();
  const [openModal, setOpenModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [forms, setForms] = React.useState<EntityFormType[]>([]);
  const [drafts, setDrafts] = React.useState<EntityFormType[]>([]);

  const onClose = (created: boolean): void => {
    setOpenModal(false);
    if (refreshRef.current && created) {
      refreshRef.current();
    }
  };

  const onRefreshCallback = (callback: Function): void => {
    refreshRef.current = callback;
  };

  React.useEffect(() => {
    const fetchFormsAndDrafts = async (): Promise<void> => {
      setLoading(true);
      try {
        const { data: formsData } = await API.getEntityForms(EntityModules.Events, {
          audience: '-public',
          active: '1',
        })();

        const { data: draftsData } = await API.getEntityFormsDrafts(EntityModules.Events)();

        setForms(formsData.results);
        setDrafts(draftsData.results);
      } catch (error) {
        Logger.error(error as string);
      } finally {
        setLoading(false);
      }
    };

    fetchFormsAndDrafts();
  }, []);

  const handleNewButtonClick = async (): Promise<void> => {
    // if there is only one form, and no drafts create a new draft
    if (forms.length === 1 && drafts.length === 0) {
      try {
        const { data } = await API.createEntityFormsDrafts(EntityModules.Events, forms[0].uuid)();
        routing.push(reverse(routes.dashboard.events.draft.details, { draftId: data.uuid }));
      } catch (error) {
        Logger.error(error as string);
      }
    }
    setOpenModal(true);
  };

  return (
    <>
      <Record
        Header={
          <MainHeader
            title="Events"
            breadcrumbs={[
              { label: 'Events', link: routes.dashboard.events.toString() },
              { label: 'Overview', link: routes.dashboard.events.toString() },
            ]}
            action={
              <Button
                variant="contained"
                size="large"
                onClick={handleNewButtonClick}
                disabled={forms.length === 0 || loading}
              >
                Report Event
              </Button>
            }
          />
        }
        Content={(onError) => <EventsTable onError={onError} onRefreshCallback={onRefreshCallback} />}
        navBarHeight={navBarHeight}
      />
      <CreateEventModal openModal={openModal} onClose={onClose} forms={forms} drafts={drafts} />
    </>
  );
};

export default Events;
