import { observer } from 'mobx-react';

import { Button } from 'vatix-ui/lib/components/Button';

import { IconButton, Link } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

import { ButtonWrapper, Container, SubmissionContainer } from 'containers/EntitiesFormSubmission/styles';

import { useStore } from 'utils/hooks/store';

import { AnswerTypes, FormBuilderProperties, ProtectorType } from 'utils/api/types';

// eslint-disable-next-line max-len
import CustomFieldDisplayValue from 'containers/IncidentDetails/components/CustomFieldDisplayValue/CustomFieldDisplayValue';

import { EntityModules } from 'core/constants';

import {
  PreviewSection,
  PreviewSectionTitle,
  PreviewSectionDescription,
  SubHeading,
  Title,
  PreviewSectionHeader,
  PreviewSectionContent,
  PreviewSectionRow,
} from './styles';
import FormDraftAdditionalComments from '../FormDraftAdditionalComments';
import SubmittedModal from '../SubmittedModal';

const PreviewSectionItem: React.FC<{
  section: FormBuilderProperties;
  getContentForKey: (questionKey: string) => AnswerTypes | undefined;
  editSection: () => void;
}> = ({ section, getContentForKey, editSection }): React.ReactElement => (
  <PreviewSection>
    <PreviewSectionHeader>
      <div>
        <PreviewSectionTitle>{section.title}</PreviewSectionTitle>
        {section.description && <PreviewSectionDescription>{section.description}</PreviewSectionDescription>}
      </div>
      <IconButton onClick={editSection} data-testid="edit-button">
        <EditIcon name="edit" htmlColor="rgba(7, 114, 229, 1)" />
      </IconButton>
    </PreviewSectionHeader>
    <PreviewSectionContent>
      {section.order.map((item) => (
        <PreviewSectionRow key={item}>
          <h1>{section.properties[item].description}</h1>
          <div style={{ flex: 6, display: 'flex', alignItems: 'center' }}>
            <CustomFieldDisplayValue
              schema={undefined}
              type={section.properties[item].questionType as ProtectorType}
              value={getContentForKey(item) as AnswerTypes}
              lookupType={
                section.properties[item].questionType === ProtectorType.Lookup
                  ? ((section.properties[item].properties.lookupType as unknown) as EntityModules)
                  : undefined
              }
            />
          </div>
        </PreviewSectionRow>
      ))}
    </PreviewSectionContent>
  </PreviewSection>
);

const FormPreview: React.FC = (): React.ReactElement => {
  const {
    entitiesFormSubmission: {
      onSubmit,
      submitting,
      editSection,
      previewData,
      getContentForKey,
      showSubmittedModal,
      isPublicSubmission,
    },
  } = useStore();

  return (
    <SubmissionContainer>
      <Container>
        <Title>Review and Submit</Title>
        <SubHeading>
          Please review your answers below to ensure all information is accurate. If you have any additional comments,
          files, or images to include, you can add them in the section provided at the bottom of this page.
        </SubHeading>
        {previewData?.stack.map((item) => (
          <PreviewSectionItem
            key={item}
            section={previewData.form.form.properties[item]}
            getContentForKey={(questionKey) => getContentForKey(questionKey, item)}
            editSection={() => editSection(item)}
          />
        ))}
        {isPublicSubmission && (
          <SubHeading>
            By submitting this form, you acknowledge that your data will be processed in accordance with our{' '}
            <Link underline="none" href="https://www.vatix.com/privacy" target="_blank">
              Privacy Policy
            </Link>
            .
          </SubHeading>
        )}
      </Container>
      <ButtonWrapper>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={() => editSection(previewData?.stack.slice(-1)[0] ?? '')}
          disabled={submitting}
        >
          Back
        </Button>
        <Button variant="contained" color="primary" size="large" onClick={onSubmit} disabled={submitting}>
          Submit
        </Button>
      </ButtonWrapper>
      {!isPublicSubmission && <FormDraftAdditionalComments />}
      <SubmittedModal openModal={showSubmittedModal} />
    </SubmissionContainer>
  );
};

export default observer(FormPreview);
