import styled from 'styled-components';

export const HeaderAction = styled.div`
  display: flex;
  flex:
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
`;

export const LastPublished = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
`;

export const SubmissionContainer = styled.div`
  margin: 0 15%;
  margin-bottom: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    margin: 0 16px;
    margin-bottom: 20px;
  }
`;

export const Container = styled.div`
  gap: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-top: 20px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: normal;
    color: rgba(0, 0, 0, 0.6);
  }
`;
