import React, { useRef, useState } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { ClickAwayListener, IconButton } from '@mui/material';

import { CSS } from '@dnd-kit/utilities';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import ClearIcon from '@mui/icons-material/Clear';

import { observer } from 'mobx-react';

import { StyledTextField } from 'components/Input/styles';
import { useStore } from 'utils/hooks/store';

import { CircleIcon } from 'components/Icons/CircleIcon';

import { CenteredContainer, ItemContainer, OptionTitle, SortableItemContainer, SubContainer } from './styles';
import { SortableItemProps } from './types';
import DropDownConditions from '../DropDownConditions';

function EditDropDownOptionItem({
  id,
  options,
  setOptions,
  item,
  isLastOption,
  sectionId,
  fieldId,
}: SortableItemProps): JSX.Element {
  const { notification, formBuilder } = useStore();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [name, setName] = useState(item.value);
  const [editingName, setEditingName] = useState(formBuilder.isDropdownOptionInEdit === item.key);

  React.useEffect(() => {
    if (editingName) {
      formBuilder.setDropdownOptionInEdit(item.key);
    }
  }, []);

  const handleEditNameOfOption = (value: string): void => {
    setName(value);
  };

  const handleClickAway = (): void => {
    if (!editingName) return;
    if (name.trim() === '') {
      notification.enqueueErrorSnackbar('Name cannot be empty. Please enter a value before saving.');
      return;
    }
    setEditingName(false);
    const newData = options.map((opt) =>
      opt.key === item.key
        ? {
            ...opt,
            value: name,
          }
        : opt
    );
    setOptions(newData);
    formBuilder.setDropdownOptionInEdit(undefined);
  };

  const onRemoveOption = (): void => {
    const newData = options.filter((opt) => opt.key !== item.key);
    setOptions(newData);
  };

  const onStartEditing = (): void => {
    formBuilder.setDropdownOptionInEdit(item.key);
    setEditingName(true);
  };

  const styledTextFieldProps = {
    autoFocus: true,
    fullWidth: true,
    sx: {
      '.MuiInputBase-input': {
        fontSize: '14px !important',
        padding: '0',
      },
      '.MuiInputBase-root fieldset': {
        border: 'none !important',
      },
    },
  };

  const conditionLogicActive = formBuilder.formAction.getActionsForQuestion(sectionId, fieldId).length > 0;

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      handleClickAway();
    }
  };

  const itemContainerRed = useRef<HTMLDivElement>(null);

  const [containerWidth, setContainerWidth] = useState(0);

  React.useEffect(() => {
    if (itemContainerRed.current) {
      // remove handler width, padding, margin, border
      setContainerWidth(itemContainerRed.current.clientWidth - 40 - 16 - 12 - 8 - 16 - 6);
    }
  }, [itemContainerRed.current]);

  return (
    <SortableItemContainer ref={setNodeRef} style={{ ...style }}>
      <div {...attributes} {...listeners} style={{ height: '20px', cursor: 'grab' }}>
        <DragIndicatorIcon style={{ fill: '#A3A1AC', height: '20px' }} />
      </div>
      <ItemContainer ref={itemContainerRed}>
        {conditionLogicActive ? (
          <DropDownConditions
            sectionId={sectionId}
            fieldId={fieldId}
            item={item}
            onRemoveOption={onRemoveOption}
            isLastOption={isLastOption}
            handleClickAway={handleClickAway}
          >
            <CircleIcon />
            {editingName ? (
              <StyledTextField
                style={{ flex: 1 }}
                size="small"
                value={name}
                onChange={(e) => handleEditNameOfOption(e.target.value)}
                onKeyDown={onKeyDown}
                {...styledTextFieldProps}
                fullWidth
                sx={{
                  flex: 1,
                  '.MuiInputBase-input': {
                    fontSize: '14px !important',
                    padding: '0',
                    margin: '0',
                  },
                  '.MuiInputBase-root fieldset': {
                    border: 'none !important',
                  },
                }}
              />
            ) : (
              <div role="button" tabIndex={0} onClick={onStartEditing} style={{ flex: 1 }}>
                <OptionTitle $maxWidth={containerWidth / 3}>{item.value}</OptionTitle>
              </div>
            )}
          </DropDownConditions>
        ) : (
          <SubContainer>
            <ClickAwayListener touchEvent="onTouchStart" mouseEvent="onMouseDown" onClickAway={handleClickAway}>
              <CenteredContainer key={`${item.key}_name`}>
                <CircleIcon />
                {editingName ? (
                  <StyledTextField
                    style={{ flex: 1 }}
                    size="small"
                    value={name}
                    onChange={(e) => handleEditNameOfOption(e.target.value)}
                    onKeyDown={onKeyDown}
                    {...styledTextFieldProps}
                  />
                ) : (
                  <div role="button" tabIndex={0} onClick={onStartEditing} style={{ flex: 1 }}>
                    <OptionTitle $maxWidth={containerWidth}>{item.value}</OptionTitle>
                  </div>
                )}
              </CenteredContainer>
            </ClickAwayListener>
            {!isLastOption && (
              <IconButton onClick={onRemoveOption}>
                <ClearIcon htmlColor="rgba(0, 0, 0, 0.54)" />
              </IconButton>
            )}
          </SubContainer>
        )}
      </ItemContainer>
    </SortableItemContainer>
  );
}

export default observer(EditDropDownOptionItem);
