import styled from 'styled-components';

export const Header = styled.div<{ $expanded: boolean }>`
  background-color: #fff;
  display: flex;

  gap: 8px;
  width: 100%;
  margin-top: 16px;
  padding: 12px 16px;
  border: 1px solid #e2e4e6;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid #e2e4e6;
  border-radius: ${({ $expanded }) => ($expanded ? 'none' : '8px')};

  h1 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.17px;
    color: rgba(0, 0, 0, 0.87);
  }

  p {
    margin: 0;
    margin-top: 4px;
    font-size: 12px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const SectionContent = styled.div`
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #e2e4e6;
  border-top: none;
  padding: 16px;
`;

export const QuestionContainer = styled.div`
  flex: 1;
  display: flex;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 8px;
  background: #f5f5f5;
  align-items: center;
`;

export const RuleRow = styled.div`
  gap: 4px;
  display: flex;
  margin-top: 8px;
  align-items: center;
`;

export const RuleHeader = styled.div`
  gap: 4px;
  display: flex;
  margin-top: 8px;
  align-items: center;
  align-self: stretch;
`;

export const SectionContentHeader = styled.h1`
  flex: 1;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.38);
`;

export const selectStyles = {
  flex: '1',
  height: '40px',
  padding: '8px 12px',
  borderRadius: '8px',
  '.MuiSelect-select': {
    maxHeight: '40px',
    padding: '0',
  },
  border: '1px solid #E2E4E6',
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

export const SelectedValue = styled.div<{ $isNone: boolean }>`
  margin: 0;
  padding: 0;
  line-height: 20px;
  font-size: 14px;
  max-height: 40px;
  font-weight: ${({ $isNone }) => ($isNone ? '400' : '500')};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
