import React from 'react';

import { Collapse } from '@mui/material';

import { ExpandMore, ExpandLess } from '@mui/icons-material';

import { useStore } from 'utils/hooks/store';

import { SectionType } from 'stores/TemplateDetails/types';

import { QuestionContainer, Row, SectionContainer, SectionContainerHeader, SectionScoreContainer } from './styles';
import QuestionRow from '../QuestionRow';
import { CollapsedSectionsType } from './types';

const CollapsedSections: React.FC<CollapsedSectionsType> = ({
  goToQuestion,
  sectionId,
  questionId,
  isSubmitted,
}): React.ReactElement => {
  const {
    formDetails: { details },
  } = useStore();

  if (details === undefined) {
    return <></>;
  }

  const [expanded, setExpanded] = React.useState(true);

  const section = details.template.content.properties[sectionId] as SectionType;
  const sectionPosition = details.template.content.order.indexOf(sectionId);

  const handleExpandClick = (): void => {
    setExpanded((prevState) => !prevState);
  };

  const { scoredPoints, allPoints, average, bgColor } = details.getSectionScore(section, sectionId);

  return (
    <SectionContainer>
      <Row>
        <SectionContainerHeader>
          {expanded ? (
            <ExpandLess onClick={handleExpandClick} aria-label="show less" color="disabled" />
          ) : (
            <ExpandMore onClick={handleExpandClick} aria-label="show more" color="disabled" />
          )}
          <p id="section-title">
            {sectionPosition}. {section.description || 'Section name'}
          </p>
        </SectionContainerHeader>
        {scoredPoints ? (
          <SectionScoreContainer $bgColor={bgColor}>
            <p id="section-score">{`${scoredPoints}/${allPoints} | ${average}`}</p>
          </SectionScoreContainer>
        ) : null}
      </Row>
      <Collapse in={expanded}>
        {section.order.map((questionIdItem) => (
          <QuestionContainer selected={questionIdItem === questionId} key={questionIdItem}>
            <QuestionRow
              questionId={questionIdItem}
              showStats
              sectionId={sectionId}
              isSubmitted={isSubmitted}
              goToQuestion={goToQuestion}
            />
          </QuestionContainer>
        ))}
      </Collapse>
    </SectionContainer>
  );
};

export default CollapsedSections;
