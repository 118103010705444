import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import debounce from 'lodash/debounce';

import { AnswerTypes, EnumOptions, FormBuilderProperties, ProtectorType } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import { EntityModules } from 'core/constants';

import { SectionHeader, QuestionsList, QuestionTitle } from './styles';
import EntitiesFormField from '../EntitiesFormField/EntitiesFormField';

const SubmissionSection: React.FC<{
  section: FormBuilderProperties;
}> = ({ section }): React.ReactElement => {
  const {
    entitiesFormSubmission: {
      getContentForKey,
      updateContentForKey,
      requiredNotFilled,
      setAutoSaving,
      isPublicSubmission,
    },
  } = useStore();

  const update = useCallback(
    debounce(
      async (type: ProtectorType, questionKey: string, value: AnswerTypes): Promise<void> => {
        if (type === ProtectorType.User) {
          await updateContentForKey(questionKey, value ? (value as string).split(':')[1] : value);
        } else {
          await updateContentForKey(questionKey, value);
        }
      },
      // we do not want to debounce public submissions
      isPublicSubmission ? 0 : 1000
    ),
    [updateContentForKey]
  );

  return (
    <>
      <SectionHeader>{section.title}</SectionHeader>
      <QuestionsList>
        {section.order.map((questionKey) => {
          const questionType = section.properties[questionKey].questionType as ProtectorType;
          const lookupType = (section.properties[questionKey].properties?.lookupType as unknown) as EntityModules;
          const description = section.properties[questionKey].description || '';

          return (
            <div key={questionKey}>
              <QuestionTitle $required={section.required.includes(questionKey)}>
                {section.properties[questionKey].description}
              </QuestionTitle>
              <EntitiesFormField
                editable
                requiredNotFilled={requiredNotFilled.includes(questionKey)}
                items={(section.properties[questionKey].properties?.enum as unknown) as EnumOptions}
                value={getContentForKey(questionKey) || ''}
                onChange={async (value) => {
                  setAutoSaving(true);
                  await update(questionType, questionKey, value);
                }}
                description={description}
                type={questionType}
                lookupType={lookupType}
              />
            </div>
          );
        })}
      </QuestionsList>
    </>
  );
};

export default observer(SubmissionSection);
