import { action, makeObservable, observable, runInAction } from 'mobx';

import { AxiosError } from 'axios';

import { reverse } from 'named-urls';

import API from 'utils/api';
import RootStore from 'stores/Root';
import { AnswerTypes, EntityFormType, EnumOptions, FormBuilderProperties, ProtectorType } from 'utils/api/types';
import Logger from 'utils/logger';
import routes from 'core/routes';
import { PublicSubmissionOptions } from 'stores/FormBuilderSettings/types';

import DraftActivities from './DraftActivities';
import SubmissionActivities from './SubmissionActivities';

export interface EntitySubmissionDraftType {
  uuid: string;
  stack: string[];
  hasNext?: boolean;
  updatedAt: string;
  createdAt: string;
  formVersion: string;
  form: EntityFormType;
  content: Record<string, AnswerTypes>;
}

export type PublicSubmissionUserDetails = {
  name: string | null;
  email: string | null;
  phone: string | null;
};

export default class EntitiesFormSubmission {
  rootStore: RootStore;

  api: typeof API;

  entityType: string | null = null;

  @observable data: EntitySubmissionDraftType | null = null;

  @observable previewData: EntitySubmissionDraftType | null = null;

  @observable isLoading: boolean = false;

  @observable isError: boolean = false;

  @observable updating: boolean = false;

  @observable submitting: boolean = false;

  @observable autoSaving: boolean = false;

  @observable showPreview: boolean = false;

  @observable requiredNotFilled: string[] = [];

  @observable showRedirectModal: boolean = false;

  @observable showSubmittedModal: boolean = false;

  @observable draftActivities: DraftActivities | null = null;

  @observable isPublicSubmission: boolean = false;

  @observable publicSubmissionUuid: string = '';

  @observable publicSubmissionUserDetailsFields: PublicSubmissionOptions | null = null;

  @observable displayUserDetailsStep: boolean = false;

  @observable userDetailsRequiredNotFilled: string[] = [];

  @observable displaySuccessPage: boolean = false;

  @observable displayReloadPage: boolean = false;

  @observable submissionActivities?: SubmissionActivities;

  @observable showConfirmPopup = false;

  @observable publicSubmissionUserDetails?: {
    name: string | null;
    email: string | null;
    phone: string | null;
  } = {
    name: null,
    email: null,
    phone: null,
  };

  constructor(rootStore: RootStore, api: typeof API) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.api = api;
  }

  @action.bound
  setDisplayReloadPage(display: boolean): void {
    runInAction(() => {
      this.displayReloadPage = display;
    });
  }

  @action.bound
  async moveToFirstSectionFromUserDetails(): Promise<void> {
    if (!this.data || !this.entityType) {
      return;
    }

    // send the user details to the server publicSubmissionDetails
    try {
      const { data } = await this.api.updateEntityFormAutosave(
        this.entityType,
        this.isPublicSubmission,
        this.publicSubmissionUuid,
        {
          content: this.data.content,
          stack: this.data.stack,
          publicSubmissionDetails: this.publicSubmissionUserDetails,
        }
      )();

      if (this.checkFormVersion(data.formVersion)) {
        this.autoSaving = false;
        return;
      }

      runInAction(() => {
        this.data = data;
        this.displayUserDetailsStep = false;
      });
    } catch (error) {
      // what kind of error I can get here
      Logger.error(error as string);
      this.rootStore.notification.enqueueErrorSnackbar('Failed to save changes');
    }
  }

  @action.bound
  checkIfAllRequiredFieldsAreFilled(): boolean {
    // Reset the array before checking
    this.userDetailsRequiredNotFilled = [];

    const fieldsToCheck: Array<keyof PublicSubmissionUserDetails> = ['name', 'email', 'phone'];

    fieldsToCheck.forEach((field) => {
      if (
        this.publicSubmissionUserDetailsFields?.[field] &&
        (!this.publicSubmissionUserDetails?.[field] || this.publicSubmissionUserDetails[field] === '')
      ) {
        this.userDetailsRequiredNotFilled.push(field);
      }
    });

    return this.userDetailsRequiredNotFilled.length === 0;
  }

  @action.bound
  saveUserDetails(key: 'name' | 'email' | 'phone', value: string | null): void {
    if (!this.publicSubmissionUserDetails) {
      return;
    }
    // if value is empty string set it to null
    if (value === '') {
      this.publicSubmissionUserDetails[key] = null;
    } else {
      this.publicSubmissionUserDetails[key] = value;
    }

    Object.keys(this.publicSubmissionUserDetails).forEach((k) => {
      if (
        this.publicSubmissionUserDetails?.[k as keyof PublicSubmissionUserDetails] === null ||
        this.publicSubmissionUserDetails?.[k as keyof PublicSubmissionUserDetails] === '' ||
        !this.publicSubmissionUserDetailsFields?.[k as keyof PublicSubmissionOptions]
      ) {
        delete this.publicSubmissionUserDetails?.[k as keyof PublicSubmissionUserDetails];
      }
    });

    this.userDetailsRequiredNotFilled = this.userDetailsRequiredNotFilled.filter((item) => item !== key);
  }

  @action.bound
  async loadPublicSubmission(publicUuid: string): Promise<void> {
    this.entityType = 'events';
    this.isPublicSubmission = true;
    try {
      this.isLoading = true;
      this.submissionActivities = new SubmissionActivities(this.rootStore, this.api, this.entityType);
      const { data } = await this.api.getPublicSubmission(this.entityType, publicUuid)();

      const hasNext = data.hasNext || data.stack.length > 1;

      runInAction(() => {
        this.data = {
          ...data,
          stack: data.stack.slice(0, 1),
          hasNext,
        };
        this.publicSubmissionUuid = publicUuid;
        // if all the fields are false set the publicSubmissionUserDetailsFields to null
        if (
          !data.form.settings.publicSubmission.name &&
          !data.form.settings.publicSubmission.email &&
          !data.form.settings.publicSubmission.phone
        ) {
          this.publicSubmissionUserDetailsFields = null;
          this.displayUserDetailsStep = false;
          this.publicSubmissionUserDetails = undefined;
        } else {
          this.publicSubmissionUserDetailsFields = data.form.settings.publicSubmission;
          this.displayUserDetailsStep = true;
        }
      });
    } catch (error) {
      this.isLoading = false;
      this.isError = true;
      Logger.error(error as string);
    }
    this.isLoading = false;
  }

  @action.bound
  async loadDraft(entityType: string, draftId: string): Promise<void> {
    this.entityType = entityType;
    this.isPublicSubmission = false;
    try {
      this.isLoading = true;
      const { data } = await this.api.getDraft(entityType, draftId)();

      const hasNext = data.hasNext || data.stack.length > 1;

      runInAction(() => {
        this.data = { ...data, stack: data.stack.slice(0, 1), hasNext };
        this.draftActivities = new DraftActivities(this.rootStore, this.api, draftId);
      });
    } catch (error) {
      Logger.error(error as string);
      // redirect to list view on 404
      if ((error as AxiosError).response?.status === 404) {
        this.rootStore.routing.push(reverse(routes.dashboard.events.toString()));
      }
      runInAction(() => {
        this.isError = true;
      });
    }
    this.isLoading = false;
  }

  @action.bound
  getCurrentSectionProperties(): FormBuilderProperties | undefined {
    if (!this.data) {
      return undefined;
    }

    const currentSection = this.data.stack[this.data.stack.length - 1];

    return this.data.form.form.properties[currentSection];
  }

  @action.bound
  getContentForKey(key: string, sectionKey?: string): AnswerTypes | undefined {
    if (!this.data) {
      return undefined;
    }
    if (sectionKey) {
      const section = this.data.form.form.properties[sectionKey];
      const { questionType } = section.properties[key];
      if (questionType === ProtectorType.MultiChoice || questionType === ProtectorType.SingleChoice) {
        const enumOptions = (section.properties[key].properties?.enum as unknown) as EnumOptions;
        const keyToValueMap = enumOptions.reduce((acc, item) => {
          acc[item.key] = item.value;
          return acc;
        }, {} as Record<string, string>);

        const content = this.data.content[key];
        if (!content) {
          return undefined;
        }

        return (content as string[]).map((k) => keyToValueMap[k]);
      }
    }

    return this.data.content[key];
  }

  checkFormVersion(newVersion: string): boolean {
    if (this.data?.formVersion !== newVersion) {
      if (this.isPublicSubmission) {
        this.rootStore.notification.enqueueWarningSnackbar(
          // eslint-disable-next-line max-len
          'The form layout has been updated and cannot be submitted. Please refresh the page to load the latest version and try again.',
          { autoClose: false }
        );
      } else {
        this.showRedirectModal = true;
      }
      return true;
    }
    return false;
  }

  @action.bound
  setAutoSaving(autoSaving: boolean): void {
    runInAction(() => {
      this.autoSaving = autoSaving;
    });
  }

  @action.bound
  async updateContentForKey(key: string, value: AnswerTypes): Promise<void> {
    if (!this.data || !this.entityType) {
      return;
    }
    // if this is a public submission do not send data to the server
    // just store it in the data
    if (this.isPublicSubmission) {
      let updatedValue = value;

      // if value is empty array or empty string set it to null
      if (value === '' || (Array.isArray(value) && value.length === 0)) {
        updatedValue = null;
      }

      this.data.content[key] = updatedValue;
      this.requiredNotFilled = this.requiredNotFilled.filter((item) => item !== key);
      return;
    }

    this.setAutoSaving(true);

    let updatedValue = value;

    // if value is empty array or empty string set it to null
    if (value === '' || (Array.isArray(value) && value.length === 0)) {
      updatedValue = null;
    }

    const oldValue = this.data.content[key];
    this.data.content[key] = updatedValue;
    this.requiredNotFilled = this.requiredNotFilled.filter((item) => item !== key);

    try {
      const { data } = await this.api.updateEntityFormAutosave(
        this.entityType,
        this.isPublicSubmission,
        this.isPublicSubmission ? this.publicSubmissionUuid : this.data.uuid,
        {
          content: { [key]: updatedValue },
          stack: this.data.stack,
          publicSubmissionDetails: this.publicSubmissionUserDetails,
        }
      )();

      if (this.checkFormVersion(data.formVersion)) {
        this.autoSaving = false;
        return;
      }

      runInAction(() => {
        this.data = data;
      });
    } catch (error) {
      // what kind of error I can get here
      Logger.error(error as string);
      this.rootStore.notification.enqueueErrorSnackbar('Failed to save changes');

      // in case of error revert the value
      this.data.content[key] = oldValue;
    }

    this.setAutoSaving(false);
  }

  @action.bound
  async getPreview(): Promise<void> {
    if (!this.data || !this.entityType) {
      return;
    }

    const dataToSend = this.isPublicSubmission
      ? {
          content: this.transformContentToSend(this.data.content),
          stack: this.data.stack,
          publicSubmissionDetails: this.publicSubmissionUserDetails,
        }
      : undefined;

    try {
      const { data } = await this.api.getPreview(
        this.entityType,
        this.isPublicSubmission,
        this.isPublicSubmission ? this.publicSubmissionUuid : this.data.uuid,
        dataToSend
      )();

      if (this.checkFormVersion(data.formVersion)) {
        return;
      }

      runInAction(() => {
        this.previewData = data;
        this.showPreview = true;
        this.data = data;
        this.updating = false;
      });
    } catch (error) {
      Logger.error(error as string);
      this.rootStore.notification.enqueueErrorSnackbar('Failed to get preview');
    }
  }

  @action.bound
  async onNextClick(): Promise<void> {
    if (!this.data || !this.entityType) {
      return;
    }

    try {
      this.updating = true;

      const dataToSend = this.isPublicSubmission
        ? {
            content: this.transformContentToSend(this.data.content),
            stack: this.data.stack,
            publicSubmissionDetails: this.publicSubmissionUserDetails,
          }
        : { stack: this.data.stack };

      const { data } = await this.api.getNextSection(
        this.entityType,
        this.isPublicSubmission,
        this.isPublicSubmission ? this.publicSubmissionUuid : this.data.uuid,
        dataToSend
      )();

      if (data.hasNext === false) {
        await this.getPreview();
        return;
      }

      if (this.checkFormVersion(data.formVersion)) {
        this.updating = false;
        return;
      }

      runInAction(() => {
        this.data = data;
        this.requiredNotFilled = [];
      });
    } catch (error) {
      this.updating = false;
      if ((error as AxiosError).response?.status === 400) {
        if ((error as AxiosError).response?.data.content) {
          runInAction(() => {
            this.requiredNotFilled = Object.keys((error as AxiosError).response?.data.content);
          });
          this.rootStore.notification.enqueueErrorSnackbar('You have to fill in all required fields.');
        }
      }
      Logger.error(error as string);
    }
    this.updating = false;
  }

  @action.bound
  async onPreviousClick(updatedStackProp?: string[]): Promise<void> {
    if (!this.data || !this.entityType) {
      return;
    }

    try {
      this.showPreview = false;
      this.updating = true;
      const updatedStack = updatedStackProp || this.data.stack.slice(0, -1);
      const dataToSend = this.isPublicSubmission
        ? {
            content: this.transformContentToSend(this.data.content),
            stack: updatedStack,
            publicSubmissionDetails: this.publicSubmissionUserDetails,
          }
        : {
            stack: updatedStack,
          };

      const { data } = await this.api.getPreviousSection(
        this.entityType,
        this.isPublicSubmission,
        this.isPublicSubmission ? this.publicSubmissionUuid : this.data.uuid,
        dataToSend
      )();

      if (this.checkFormVersion(data.formVersion)) {
        return;
      }

      runInAction(() => {
        this.data = data;
      });
    } catch (error) {
      Logger.error(error as string);
    }
    this.updating = false;
  }

  @action.bound
  async editSection(section: string): Promise<void> {
    if (!this.data) {
      return;
    }
    this.showPreview = false;
    // find the section in the stack and truncate the stack to that section
    const index = this.data.stack.indexOf(section);
    if (index !== -1) {
      this.data.hasNext = true;
      await this.onPreviousClick(this.data.stack.slice(0, index + 1));
    }
  }

  private transformContentToSend(data: Record<string, AnswerTypes>): Record<string, AnswerTypes> {
    const content = { ...data };

    Object.keys(data).forEach((key) => {
      const value = data[key];
      // if it is an object and it has uuid property, set it to uuid
      if (value && typeof value === 'object' && !Array.isArray(value) && 'uuid' in value) {
        content[key] = value.uuid;
      } else {
        content[key] = value;
      }
    });

    return content;
  }

  @action.bound
  changeShowConfirmPopup(value: boolean): void {
    this.showConfirmPopup = value;
  }

  @action.bound
  async onSubmit(): Promise<void> {
    if (!this.data || !this.entityType) {
      return;
    }

    this.submitting = true;

    const dataToSend = this.isPublicSubmission
      ? {
          content: this.transformContentToSend(this.data.content),
          stack: this.data.stack,
          publicSubmissionDetails: this.publicSubmissionUserDetails,
        }
      : undefined;

    try {
      const { data } = await this.api.submitEntityForm(
        this.entityType,
        this.isPublicSubmission,
        this.isPublicSubmission ? this.publicSubmissionUuid : this.data.uuid,
        dataToSend
      )();

      // if publicSubmission send also submissionActivities
      if (this.isPublicSubmission) {
        if (
          this.submissionActivities &&
          this.submissionActivities.activities &&
          this.submissionActivities.activities?.length > 0
        ) {
          await this.submissionActivities?.addSubmissionActivity(data.uuid);
        } else {
          this.changeShowConfirmPopup(true);
          await new Promise((resolve) => setTimeout(resolve, 2000));
          this.changeShowConfirmPopup(false);

          this.submissionActivities?.changeFinishedUploading(true);
        }

        this.displaySuccessPage = true;
      } else {
        this.showSubmittedModal = true;
        await new Promise((resolve) => setTimeout(resolve, 2000));
        this.showSubmittedModal = false;
        this.rootStore.routing.push(reverse(routes.dashboard.events.details, { entityId: data.uuid }));
      }
    } catch (error) {
      Logger.error(error as string);
      this.rootStore.notification.enqueueErrorSnackbar('Failed to submit form');
    } finally {
      this.submitting = false;
    }
  }

  @action.bound
  reset(): void {
    this.data = null;
    this.isError = false;
    this.updating = false;
    this.entityType = null;
    this.isLoading = false;
    this.autoSaving = false;
    this.submitting = false;
    this.previewData = null;
    this.showPreview = false;
    this.requiredNotFilled = [];
    this.showRedirectModal = false;
    this.showSubmittedModal = false;

    this.displaySuccessPage = false;
    this.publicSubmissionUserDetails = {
      name: null,
      email: null,
      phone: null,
    };
    this.publicSubmissionUserDetailsFields = null;
    this.displayUserDetailsStep = false;
  }
}
